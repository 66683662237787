// import {
//   sequence,
//   trigger,
//   stagger,
//   animate,
//   style,
//   group,
//   query as q,
//   transition,
//   keyframes,
//   animateChild,
// } from '@angular/animations';

// const query = (s, a, o = { optional: true }) => q(s, a, o);

// export const childRouterTransition = trigger('childRouterTransition', [
//   transition('* => *', [
//     query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
//     query(':enter', style({ transform: 'translateY(100%)' })),
//     sequence([
//       query(':leave', animateChild()),
//       group([
//         query(':leave', [
//           style({ transform: 'translateY(0%)' }),
//           animate('1s ease-in-out', style({ transform: 'translateY(-100%)' })),
//         ]),
//         query(':enter', [
//           style({ transform: 'translateY(100%)' }),
//           animate('1s ease-in-out', style({ transform: 'translateY(0%)' })),
//         ]),
//       ]),
//       query(':enter', animateChild()),
//     ]),
//   ]),
// ]);

import {
  sequence,
  trigger,
  stagger,
  animate,
  style,
  group,
  query as q,
  transition,
  keyframes,
  animateChild,
} from '@angular/animations';

const query = (s, a, o = { optional: true }) => q(s, a, o);

export const childRouterTransition = trigger('childRouterTransition', [
  transition('* => *', [
    query(':enter', style({ opacity: 0 })),
    sequence([
      group([
        query(':leave', animateChild()),
        query(':leave', [style({ opacity: 1 }), animate('1s ease-in-out', style({ opacity: 0 }))]),
        query(':enter', [
          style({ opacity: 0 }),
          animate('1s 0.5s ease-in-out', style({ opacity: 1 })),
        ]),
        query(':enter', animateChild()),
      ]),
    ]),
  ]),
]);
